<template>
  <section class="slider">
    <b-carousel
      id="carousel-1"
      v-model="slide"
      :interval="4000"
      controls
      indicators
      background="#ababab"
      img-width="1024"
      img-height="490"
      style="text-shadow: 1px 1px 2px #333;"
      @sliding-start="onSlideStart"
      @sliding-end="onSlideEnd"
    >
      <b-carousel-slide v-for="item in slides" v-bind:key="item.id">
          <h2>{{ item.title }}</h2>
          <template v-slot:img>
            <img
            class="d-block car-image-slot"
            :src="item.src">
          </template>
      </b-carousel-slide>
    </b-carousel>
  </section>
</template>

<script>
/* eslint-disable */
import slide1 from '@/assets/sample-slide-1.jpg'

export default {
  data() {
    return {
      slide: 0,
      sliding: null,
      slidesOld: [
        {
          id: 1,
          src: slide1,
          title: 'Saint Martin is opened as country #200!',
        },
        {
          id: 2,
          src: slide1,
          title: 'Saint Martin is open as country #200!',
        },
      ],
    }
  },
  props: {
    slides: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    onSlideStart(slide) {
      this.sliding = true
    },
    onSlideEnd(slide) {
      this.sliding = false
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/settings.scss';
@import '@/styles/components/component-carousel.scss';

.car-image-slot {
  height:490px !important;
  margin: 0 auto;
  width: auto;
  @include breakpoint(sm) {
    height:300px !important;
  }
  @include breakpoint(mobile) {
    height:200px !important;
  }
}
</style>

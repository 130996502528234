<template>
  <div class="home convention-promo-wrapper template-1">
    <div class="page-body container mx-lg">
      <header class="page-header convention-promo-header">
        <h1>{{ this.convention.evn_name }}</h1>
      </header>

      <div class="main-top-content">
        <div class="row card-deck text-center">
          <div class="card col-6 offset-md-3">
            <p class="main-text-data">{{ this.presentationDate }}</p>
            <p class="main-text-data" v-if="convention.evl_name">
              {{ this.convention.evl_name }}
            </p>
            <p class="main-text-data" v-if="convention.evl_adr_line1">
              {{ this.convention.evl_adr_line1 }}
              {{ this.convention.evl_adr_line2 }}
            </p>
            <p class="main-text-data" v-if="convention.evl_city || convention.evl_state">
              {{ this.convention.evl_city }}
              <span v-if="convention.evl_city && convention.evl_state">,</span>
              {{ this.convention.evl_state }}
              {{ this.convention.evl_postal_code }}
            </p>
          </div>
          <div class="card col-2" v-if="showLocationMap">
            <a role="button" target="_blank" :href="mapURL" class="btn btn-primary">{{ translations.tcMap }}</a>
          </div>
        </div>
      </div>
    </div>

    <app-carousel-slider v-if="convention.photos.length > 0" :slides="preppedSlides"></app-carousel-slider>

    <section class="conventionpromo-register">
      <div class="container">
        <div class="text-center">
          <div class="col" v-if="registerURL">
            <a
              role="button"
              tabindex="0"
              :href="prepExtUrl(registerURL)"
              target="_blank"
              class="btn btn-secondary btn-register"
            >
              {{ translations.tcClickToRegister }}
            </a>
          </div>
        </div>
      </div>
    </section>

    <section class="connect">
      <h2 class="mb-0">{{ translations.tcEventInformation }}</h2>
    </section>

    <section class="conventionpromo-events">
      <div class="container actions">
        <div class="card-deck row">
          <div class="card card-secondary col">
            <h2 class="card-title">
              {{ translations.tcGideonAuxiliaryContact }}
            </h2>
            <div class="card-body">
              <h3 v-if="convention.evc_name">{{ convention.evc_name }}</h3>
              <div class="contact-info">
                <p
                  class="main-text-data"
                  v-if="
                    !convention.evc_name &&
                    !convention.evc_adr_line1 &&
                    !convention.evc_city &&
                    !convention.evc_state &&
                    !convention.evc_phone_number &&
                    !convention.evc_email
                  "
                >
                  {{ translations.tcNoInformationAvailable }}
                </p>
                <p></p>
                <p v-if="convention.evc_adr_line1">{{ convention.evc_adr_line1 }} {{ convention.evc_adr_line2 }}</p>
                <p v-if="convention.evc_city || convention.evc_state">
                  {{ convention.evc_city }}
                  <span v-if="convention.evc_city && convention.evc_state">,</span>
                  {{ convention.evc_state }} {{ convention.evc_postal_code }}
                </p>
                <p v-if="convention.evc_phone_number || convention.evc_email">
                  {{ convention.evc_phone_number }}
                  <span v-if="convention.evc_phone_number && convention.evc_email">|</span>
                  <a :href="'mailto:' + convention.evc_email">{{ convention.evc_email }}</a>
                </p>
              </div>
              <div class="contact-bottom" v-if="convention.evc_other_info">
                <p>{{ convention.evc_other_info }}</p>
              </div>
            </div>
          </div>

          <div class="card card-secondary col">
            <h2 class="card-title">{{ translations.tcHotelInformation }}</h2>
            <div class="card-body">
              <h3 v-if="convention.evh_name">{{ convention.evh_name }}</h3>
              <div class="contact-info">
                <p
                  class="main-text-data"
                  v-if="
                    !convention.evh_name &&
                    !convention.evh_adr_line1 &&
                    !convention.evh_city &&
                    !convention.evh_state &&
                    !convention.evh_phone_number &&
                    !convention.evh_email
                  "
                >
                  {{ translations.tcNoInformationAvailable }}
                </p>
                <p></p>
                <p>{{ convention.evh_adr_line1 }} {{ convention.evh_adr_line2 }}</p>
                <p>
                  {{ convention.evh_city }}
                  <span v-if="convention.evh_city && convention.evh_state">,</span>
                  {{ convention.evh_state }} {{ convention.evh_postal_code }}
                </p>
                <p>
                  {{ convention.evh_phone_number }}
                  <span v-if="convention.evh_phone_number && convention.evh_email">|</span>
                  <a :href="'mailto:' + convention.evh_email">{{ convention.evh_email }}</a>
                </p>
              </div>
              <div class="contact-bottom">
                <p>
                  <a :href="prepExtUrl(convention.evh_website)" v-if="convention.evh_website" target="_blank">{{
                    translations.tcHotelWebsite
                  }}</a>
                  <a role="button" :href="hotelMapURL" class="btn btn-primary ml-5" v-if="showHotelMap" target="_blank">
                    {{ translations.tcMap }}
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="card-deck row">
          <div class="card card-secondary col">
            <h2 class="card-title">{{ translations.tcDocuments }}</h2>
            <div class="card-body">
              <div class="contact-info">
                <p class="main-text-data" v-if="convention.documents.length === 0">
                  {{ translations.tcNoInformationAvailable }}
                </p>
                <p></p>
                <p v-for="doc in convention.documents" :key="doc.evd_key">
                  <a :href="doc.evd_doc_url_path" download target="_blank">{{ doc.evd_doc_name }}</a>
                </p>
              </div>
            </div>
          </div>
          <div class="card card-secondary col">
            <h2 class="card-title">{{ translations.tcLinks }}</h2>
            <div class="card-body">
              <div class="contact-info">
                <p class="main-text-data" v-if="convention.links.length === 0">
                  {{ translations.tcNoInformationAvailable }}
                </p>
                <p></p>
                <p v-for="link in convention. links" :key="link.evl_key">
                  <a :href="prepExtUrl(link.evl_link_url)" target="_blank">{{ link.evl_display_name }}</a>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="card-deck row">
          <div class="card card-secondary col">
            <h2 class="card-title">{{ translations.tcAdditionalInformation }}</h2>
            <div class="card-body">
              <div class="contact-info">
                <p class="main-text-data" v-if="!convention.evn_additional_info">
                  {{ translations.tcNoInformationAvailable }}
                </p>
                <pre>{{ convention.evn_additional_info }}</pre>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import date_data from '@/json/date.json'
import Carousel from '@/components/CarouselSliderConventions.vue'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'convention-promo',
  mixins: [translationMixin],
  data() {
    return {
      translations: {},
      convention: {
        evn_key: null,
        evn_name: '',
        evn_start_date: null,
        evn_end_date: null,
        evn_additional_info: '',
        evn_registration_link: '',
        evn_state_assoc_key: null,
        evn_state_assoc_name: '',
        evn_delete_flag: false,
        evn_active_flag: false,
        evt_key: null,
        evt_description: '',
        evl_key: null,
        evl_name: '',
        evl_adr_line1: '',
        evl_adr_line2: null,
        evl_city: '',
        evl_state: '',
        evl_postal_code: '',
        evc_key: null,
        evc_name: '',
        evc_adr_line1: '',
        evc_adr_line2: null,
        evc_city: '',
        evc_state: '',
        evc_postal_code: '',
        evc_email: '',
        evc_phone_number: '',
        evc_other_info: '',
        evh_key: null,
        evh_name: '',
        evh_adr_line1: '',
        evh_adr_line2: null,
        evh_city: '',
        evh_state: '',
        evh_postal_code: '',
        evh_email: '',
        evh_phone_number: '',
        evh_website: '',
        photos: [],
        documents: [],
        links: [],
      },
    }
  },
  async created() {
    await this.pageLoad()
  },
  methods: {
    ...mapActions({
      loadConventionInfo: 'stateConvention/loadConventionInfo',
      setLoadingStatus: 'menu/setLoadingStatus',
    }),
    async pageLoad() {
      if (!this.userSelectedConventionKey) {
        this.$router.push('/calendar/events/view-events/st')
      }
      await Promise.all([
        this.setLoadingStatus(true),
        await this.getTranslations(),
        await this.loadConventionInfo(this.userSelectedConventionKey),
      ]).then(() => {
        this.convention = { ...this.convention_info }
        this.convention.documents = [...this.convention_info.documents]
        this.convention.photos = [...this.convention_info.photos]
        this.setLoadingStatus(false)
      })
    },
    prepExtUrl(website) {
      if (!website) {
        return null
      }
      const hasHttp = website.includes('http://') || website.includes('https://')
      if (!hasHttp) {
        website = 'https://' + website
      }
      return website
    },
  },
  computed: {
    ...mapGetters({
      userSelectedConventionKey: 'user/userSelectedConventionKey',
      userId: 'user/userId',
      convention_info: 'stateConvention/convention_info',
      prefCulture: 'user/userPreferredCulture',
    }),
    months() {
      return date_data.months.map((dm) => dm.text)
    },
    presentationDate() {
      if (!this.convention.evn_start_date) return ''

      const startOptions = { month: 'long', day: 'numeric' }  // `March 9`
      const endOptions = { year: 'numeric', month: 'long', day: 'numeric' }  // `March 12, 2021`

      const startDate = this.returnFormattedDate(this.convention.evn_start_date, startOptions)
      const endDate = this.returnFormattedDate(this.convention.evn_end_date, endOptions)
      const endDateText = endDate ? ` - ${endDate}`: ''

      return `${startDate}${endDateText}`
    },
    mapURL() {
      const base = 'https://www.google.com/maps/search/?api=1&query='
      const address = [
        this.convention.evl_adr_line1,
        this.convention.evl_adr_line2,
        this.convention.evl_city,
        this.convention.evl_state,
      ]
        .filter(Boolean)
        .join(',')
      const encodedAddress = `${encodeURIComponent(address)}`
      return base + encodedAddress
    },
    showLocationMap() {
      return !!this.convention.evl_adr_line1
    },
    hotelMapURL() {
      const base = 'https://www.google.com/maps/search/?api=1&query='
      const address = [
        this.convention.evh_adr_line1,
        this.convention.evh_adr_line2,
        this.convention.evh_city,
        this.convention.evh_state,
      ]
        .filter(Boolean)
        .join(',')
      const encodedAddress = `${encodeURIComponent(address)}`
      return base + encodedAddress
    },
    showHotelMap() {
      return !!this.convention.evh_adr_line1
    },
    registerURL() {
      let rtnURL = ''

      //check if a CVENT registration -- example: https://cvent-gideons.ae-admin.com/sso?m=[ind_key]&e=Y3NT8VDZD9G
      const isCvent = this.convention.evn_registration_link
        ? this.convention.evn_registration_link.toUpperCase().includes('cvent-gideons'.toUpperCase())
        : false
      rtnURL = isCvent
        ? this.convention.evn_registration_link.replace('[ind_key]', this.userId)
        : this.convention.evn_registration_link

      return rtnURL
    },
    preppedSlides() {
      const ps = this.convention.photos.map((p, index) => {
        return {
          id: index,
          src: p.evp_image_url_path,
          title: p.evp_image_name,
        }
      })
      return ps
    },
  },
  components: {
    appCarouselSlider: Carousel,
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/settings.scss';
@import '@/styles/views/ChurchProfile.scss';
@import '@/styles/views/ConventionPromo.scss';

.btn {
  border: none;
  border-radius: 0;
  padding: 12px 35px;
  font-size: 1rem;
  font-weight: 700;
}

.btn-register {
  font-size: 1.3rem;
}

.btn-primary {
  background-color: $primary-color;
}

.convention-promo-header {
  padding: 75px 15px 0px !important;
}

h2.card-title {
  font-size: 24px;
  font-family: 'DIN 1451 W01 Engschrift', sans-serif;
}
pre {
  overflow-x: auto;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
  background-color: #fff;
  padding: 0;
  border: 0;
  margin-top: 0;

  font-size: 16px;
  line-height: 26px;
  color: #636363;
  font-weight: 600;
  font-family: 'Open Sans';
  margin-bottom: 5px;
  min-height: auto;
}
h2 {
  text-transform: uppercase;
}
</style>
